import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/vercel/workpath0/src/templates/content.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Types of Reactions`}</h1>
    <p>{`Synthesis reaction - two substances (generally elements) combine and form a compound. `}</p>
    <p>{`Ionic compounds as products are always solids. `}</p>
    <p>{`Decomposition reaction - a compound breaks up into the elements or in a few to simpler compounds. `}</p>
    <ul>
      <li parentName="ul">{`carbonates produce carbon dioxide gas`}</li>
      <li parentName="ul">{`nitrates produce nitrogen oxide gases`}</li>
      <li parentName="ul">{`sulfates produce sulfur oxide gases`}</li>
      <li parentName="ul">{`chlorates produce oxygen gas`}</li>
      <li parentName="ul">{`oxide produces oxygen gas`}</li>
      <li parentName="ul">{`hydroxides produce water vapor`}</li>
      <li parentName="ul">{`peroxides (e.g. H2O2) produce oxygen gas and oxides (e.g. O2+ H2O)`}</li>
      <li parentName="ul">{`metal oxide + water = base`}</li>
      <li parentName="ul">{`non metal oxide + water = acid`}</li>
    </ul>
    <p>{`Combustion reaction - occur when a hydrocarbon reacts with oxygen gas (also known as burning). `}</p>
    <p>{`Fire triangle: a fuel (hydrocarbon), oxygen to burn it with, spark to ignite it. `}</p>
    <p>{`Products in combustion are always carbon dioxide and water (although incomplete burning does cause some by-products like carbon monoxide). `}</p>
    <h1>{`Balancing Equations`}</h1>
    <ul>
      <li parentName="ul">{`balance only one element at a time`}</li>
      <li parentName="ul">{`balance single elements last`}</li>
      <li parentName="ul">{`leave oxygen and hydrogen to balance last`}</li>
      <li parentName="ul">{`polyatomic ions that remain unchanged from reactant side to product side, keep as one balance these first`}</li>
    </ul>
    <p>{`Don't forget to re-check the equation at the end, as amounts may have shifted. `}</p>
    <h1>{`Weights and Masses`}</h1>
    <p>{`Formula weight (FW) - sum of the atomic weights for the atoms in a chemical formula. `}</p>
    <p>{`Molecular weight (MW) - the sum of the atomic weights of the atoms in a molecule. `}</p>
    <p>{`Ionic compounds form three-dimensional crystal structures, so they only have empirical formulas and formula weights, not molecular formulas or molecular weights. `}</p>
    <p>{`Molar mass - mass of 1 mol of a substance. `}</p>
    <p>{`This is the mass listed on the periodic table (e.g. carbon has a mass of 12.01 g/mol, also 12.01 amu). `}</p>
    <h1>{`Avogadro's Number`}</h1>
    <p>{`Avogadro's Number is ~6.022 * 10^23`}</p>
    <p>{`It's the number of particles in 1 mol. `}</p>
    <p>{`A mol is a unit like a dozen, it's a measure of items of a type. `}</p>
    <p>{`1 mol H2O contains 2 mol hydrogen and 1 mol oxygen`}</p>
    <p>{`Moles are used to convert between grams and number of atoms or molecules of a substance — they're the connector unit. `}</p>
    <h1>{`Determining Empirical Formula from Percent Composition`}</h1>
    <p>{`Learn by example: `}</p>
    <p><strong parentName="p">{`question:`}</strong>{` `}</p>
    <p><em parentName="p">{`A compound of B and H is 81.10% B. What is its empirical formula?`}</em>{` `}</p>
    <p><strong parentName="p">{`answer:`}</strong>{` `}</p>
    <p>{`When given percentages, use 100g to calculate`}</p>
    <p>{`81.10g B * (1 mol / 10.81g) = 7.502 mol B`}</p>
    <p>{`100 - 81.10g H * (1 mol / 1.01g) = 18.713 mol H`}</p>
    <p>{`Divide each calculated result by lower number of moles: `}</p>
    <p>{`7.502 / 7.502 = 1`}</p>
    <p>{`18.713 / 7.502 = about 2.5 `}<em parentName="p">{`(you'll have to round, there's always experimental error)`}</em></p>
    <p>{`Then, multiply to get a whole-number empirical formula from that ratio`}</p>
    <p>{`B2H5`}</p>
    <h1>{`Determining Molecular Formula from Empirical Formula`}</h1>
    <p>{`For this you need the molecular mass, which is found through experimentation. `}</p>
    <p>{`Then, take the mass of the empirical formula (e.g. C2H3 = 27 g/mol) and compare with molecular mass (e.g. 81 g/mol), to find how much you need to multiply the empirical formula by to get the molecular formula (e.g. 81/27 = 3, so molecular formula is C6H9). `}</p>
    <h1>{`Hydrates`}</h1>
    <p>{`A hydrate is a salt that when crystallized forms an aqueous solution that incorporates a fixed amount of water in its crystalline matrix, even while appearing dry. `}</p>
    <p>{`The number of moles of water present per one more of anhydrous salt is usually a simple whole number. `}</p>
    <h2>{`Calculation`}</h2>
    <ol>
      <li parentName="ol">{`calculate the mass lost during the heating, which is the mass of H2O lost`}</li>
      <li parentName="ol">{`calculate moles of H2O lost`}</li>
      <li parentName="ol">{`calculate moles of substance `}<em parentName="li">{`not including water`}</em></li>
      <li parentName="ol">{`similarly to finding empirical formulas, divide and find the ratio of substance : H2O`}</li>
    </ol>
    <h1>{`Combustion Analysis`}</h1>
    <p>{`in these problems, you are given the mass of the hydrocarbon, and resulting masses of CO2 and H2O. `}</p>
    <p>{`C is determined by calculating moles of CO2 produced. `}</p>
    <p>{`H is determined by calculating moles of H2O produced, then multiplying by two (because it's H2, there's 2 mol H per 1 mol H2O). `}</p>
    <p>{`If the hydrocarbon is of the form C`}<em parentName="p">{`H_O`}</em>{` or C`}<em parentName="p">{`H_N`}</em>{`, you can calculate the mass of the O or N by subtracting the masses of C and H from the mass of the hydrocarbon. `}</p>
    <p>{`Then, find the moles of O or N, and find the empirical formula by dividing moles by the lowest number of moles (like usual) and finding the ratio. `}</p>
    <h1>{`Limiting Reactant`}</h1>
    <p>{`The limiting reactant is the one that will be used up first in the reaction, and restricts the amount of product that can be created. `}</p>
    <ul>
      <li parentName="ul">{`to calculate which reactant is the limiting reactant, convert the amount of each reactant given to moles`}</li>
      <li parentName="ul">{`then, compare these amounts with the moles of reactant needed (remember, the coefficient = the # of moles needed)`}</li>
      <li parentName="ul">{`just thinking about these ratios should make clear which reactant is limiting`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      